import React from "react";
import getIconAttributes, { IconProps } from "./icon";

const RunicLogo = (props: IconProps) => {
    const svgAttributes = getIconAttributes(props);
    return (
        <svg width={svgAttributes.width}
             height={svgAttributes.height}
             className={svgAttributes.className}
             data-name="runic"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 321.5 71.1"
             fill="none"
        >
            <polygon data-name="top check" fill={"#82f9d2"}
                     points="64.26 25.65 38.61 0 38.61 0 0 38.61 6.76 45.37 39.92 12.22 58.8 31.1 64.26 25.65"/>
            <polygon data-name="bottom check" fill={"#82f9d2"}
                     points="20.37 45.45 46.01 71.09 46.02 71.1 84.62 32.49 77.86 25.73 44.71 58.88 25.82 40 20.37 45.45"/>
            <polygon data-name="n"
                     points="200.2 8.25 200.2 62.85 209.76 62.85 209.76 15.96 233.76 15.96 233.76 62.85 243.33 62.85 243.33 8.25 200.2 8.25"
                     fill={"#000"}
            />
            <path data-name="u"
                  fill={"#000"}
                  d="m180.47,40.18c0,10.02-2.71,15.82-12.73,15.82s-12.8-5.5-12.8-15.52V8.25h-9.56v31.86c0,17.62,6.7,23.72,22.37,23.72s22.29-6.78,22.29-24.03V8.25h-9.56v31.93Z"/>
            <rect data-name="i" x="254.32" y="8.25" width="9.56" height="54.6" fill={"#000"} />
            <path data-name="c"
                  fill={"#000"}
                  d="m312.31,44.7c-1.66,7.46-6.93,11.3-14.31,11.3-8.74,0-15.44-7.38-15.44-20.64s6.85-20.26,15.52-20.26c7.08,0,12.43,3.92,14.08,11.3h9.19c-2.18-12.2-10.84-19.13-22.97-19.13-15.82,0-25.68,10.92-25.68,28.09s9.26,28.47,25.23,28.47c12.58,0,21.31-6.93,23.57-19.13h-9.19Z"/>
            <polygon data-name="r"
                     fill={"#000"}
                     points="104.78 8.25 104.78 62.85 114.34 62.85 114.34 15.96 138.35 15.96 138.35 8.25 104.78 8.25"/>
        </svg>
    );
};

export default RunicLogo;