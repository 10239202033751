export type IconProps = {
  fill?: string;
  class?: string;
  width: number;
  height: number;
}

export type SvgAttributes = {
  fill?: string;
  className?: string;
  width: number;
  height: number;
}

const DEFAULT_FILL = "#1D4ED8";

const getIconAttributes = (props: IconProps): SvgAttributes => {
  const svgAttributes: SvgAttributes = {
    width: props.width,
    height: props.height,
  }
  if (props.fill) {
    svgAttributes.fill = props.fill
  } else {
    svgAttributes.fill = DEFAULT_FILL
  }
  if (props.class) {
    svgAttributes.className = props.class
  }
  return svgAttributes
}

export default getIconAttributes;