import React from "react";

enum SpinnerFills {
  GRAY100 = "#eff2f5",
}

const Spinner = ({
  width,
  height,
  fill,
  className,
  screenReaderText
}: {
  width: number;
  height: number;
  fill?: string;
  className?: string;
  screenReaderText?: string;
}) => {
  const fillValue = fill ?? SpinnerFills.GRAY100;
  const screenReaderTextValue = screenReaderText ?? "Loading..."
  return (
    <div role={"status"} className={className ?? undefined}>
      <svg
        className={"animate-spin"}
        width={width}
        height={height}
        fill={fillValue}
        viewBox={`0 0  390.68 390.68`}
      >
        <path d="M390.68,195.34C390.68,87.46,303.22,0,195.34,0A195.18,195.18,0,0,0,2.25,165.59c-.49,3.23-.91,6.49-1.24,9.78a196.36,196.36,0,0,0,14.34,96q1.86,4.38,3.91,8.66t4.32,8.42q4.5,8.3,9.78,16.1,1.32,1.95,2.68,3.87,2.72,3.82,5.62,7.52,1.46,1.85,3,3.65,3,3.62,6.14,7.09,1.57,1.74,3.19,3.43a195.75,195.75,0,0,0,61,43.31q2.16,1,4.35,1.91a195.61,195.61,0,0,0,186.31-18.75l-16.07-24.11A166.35,166.35,0,0,1,77.72,77.72,166.36,166.36,0,0,1,332.9,288.91l23.91,16.38A194.43,194.43,0,0,0,390.68,195.34Z" />
      </svg>
      <span className={"sr-only"}>{screenReaderTextValue}</span>
    </div>
  );
};

export default Spinner;
