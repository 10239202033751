import * as React from "react";
import { PageProps } from "gatsby";
import UserSignIn from "../components/user/UserSignIn";
import queryString from "query-string";
import { LOCAL_REDIRECT_KEY } from "../components/user/WithAuth";

const SignIn = (props: PageProps) => {

  let redirect = "/"
  if (typeof window !== "undefined") {
    if (queryString.parse(window.location.search).redirect === 'true') {
      if (localStorage.getItem(LOCAL_REDIRECT_KEY)) {
        redirect = localStorage.getItem(LOCAL_REDIRECT_KEY)
      }
    }
  }

  return <UserSignIn redirect={redirect} />;
};

export const Head = () => (
  <>
    <title>Runic | Sign In</title>
  </>
)

export default SignIn;