import * as React from "react";
import { navigate, PageProps } from "gatsby";
import { userPool } from "./CognitoConfig";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { createContext, useEffect, useState } from "react";
import { PagePropsWithChildren } from "../layout/PagePropsWithChildren";
import Spinner from "../animation/Spinner";

type UserData = {
  cognitoUser: undefined | CognitoUser;
  cognitoSession: undefined | CognitoUserSession;
  isAuthenticated: undefined | boolean;
}

export type AuthenticatedUserData = {
  cognitoUser: CognitoUser;
  cognitoSession: CognitoUserSession;
  isAuthenticated: true;
}

const DefaultUserData = {
  isAuthenticated: undefined,
  cognitoUser: undefined,
  cognitoSession: undefined
}

export const UserContext = createContext<UserData>(DefaultUserData)

export const LOCAL_REDIRECT_KEY = 'pop.auth_redirect'

const WithAuth = (props: PagePropsWithChildren) => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined)
  const [userData, setUserData] = useState<UserData>(DefaultUserData)

  const checkAuth = () => {
    const user = userPool.getCurrentUser()
    if (!user) {
      setIsLoading(false)
      setIsAuthenticated(false)
    } else {
      user.getSession((error, session) => {
        if (error || !session) {
          setIsLoading(false)
          setIsAuthenticated(false)
        } else {
          setIsLoading(false)
          setIsAuthenticated(true)
          setUserData({
            isAuthenticated: true,
            cognitoUser: user!,
            cognitoSession: session!
          })
        }
      });
    }
  }

  useEffect(() => {
    checkAuth()
  }, [])

  const getPage = () => {
    if (isLoading) {
      return (
        <div className={"w-full m-auto flex items-center"}>
          <Spinner className={"m-auto"} width={300} height={300} />
        </div>
      )
    }
    if (!isLoading && !isAuthenticated) {
      localStorage.setItem(LOCAL_REDIRECT_KEY, `${window.location.pathname}${window.location.search ?? ''}`)
      navigate("/signin?redirect=true")
      return null
    }
    return (
      <UserContext.Provider value={userData}>
        {props.children}
      </UserContext.Provider>
    )
  }

  return getPage()
};


export default WithAuth;